<template>
  <FPotensiTable
      :kawasan = "false"
  ></FPotensiTable>
</template>

<script>
import FPotensiTable from "../../components/admin_potensi/potensi/FPotensiTable";
export default {
  name: "PotensiView",
  components:{
    FPotensiTable
  }
}
</script>

<style scoped>

</style>